import {
  Create,
  SimpleForm,
  TextInput,
  FileInput,
  FileField,
  NumberInput,
} from 'react-admin'

export const CreatePage = () => {
  return (
    <Create title="Добавить семинар" redirect="list">
      <SimpleForm>
        <TextInput source="name" label="Название" required={true} />
        <TextInput source="description" label="Описание" required={true} />
        <NumberInput source="price" label="Цена" required={true} />
        <FileInput
          source="filePath"
          label="Видео"
          accept="video/mp4"
          isRequired={true}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  )
}
