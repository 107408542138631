import {
  Edit,
  FileInput,
  SimpleForm,
  TextInput,
  NumberInput,
} from 'react-admin'
import { VideoPlayer } from '../../common/videoPlayer/VideoPlayer'

export const EditPage = () => {
  return (
    <Edit title="Редактирование">
      <SimpleForm>
        <TextInput
          source="name"
          label="Название"
          required={true}
          parse={(value: string) => (value ? value.trim() : '')}
        />
        <TextInput source="description" label="Описание" required={true} />
        <NumberInput source="price" label="Цена" required={true} />
        <FileInput source="filePath" label="Видео" accept="video/mp4">
          <div style={{ width: 400 }}>
            <VideoPlayer source="filePath" />
          </div>
        </FileInput>
      </SimpleForm>
    </Edit>
  )
}
