import config from '../constants/config'
import jsonServerProvider from 'ra-data-json-server'
import {
  CreateParams,
  fetchUtils,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { CreateResult, RaRecord } from 'ra-core'
import inMemoryJWT from '../Auth/inMemoryJWT'

const fetchJson = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const token = inMemoryJWT.getToken()
  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`)
    return fetchUtils.fetchJson(url, options)
  } else {
    inMemoryJWT.setRefreshTokenEndpoint(`${config.apiUrl}/admin-users/refresh`)
    return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
      if (gotFreshToken) {
        options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`)
      }
      return fetchUtils.fetchJson(url, options)
    })
  }
}

const dataProvider = jsonServerProvider(`${config.apiUrl}`, fetchJson)

const myDataProvider = () => {
  return {
    ...dataProvider,
    create: <RecordType extends RaRecord = RaRecord>(
      resource: string,
      params: CreateParams,
    ): Promise<CreateResult<RecordType>> => {
      try {
        let formData = new FormData()
        switch (resource) {
          case 'seminars':
            formData.append('name', params.data.name)
            formData.append('price', params.data.price.toString())
            formData.append('description', params.data.description)

            if (
              params.data.hasOwnProperty('filePath') &&
              params.data.filePath
            ) {
              const file = params.data.filePath.rawFile
              formData.append('filePath', file, file.name)
            }

            return fetchJson(`${config.apiUrl}/${resource}`, {
              method: 'POST',
              body: formData,
            }).then(({ json }) => ({
              data: { ...params.data, id: json.id },
            }))
          default:
            return dataProvider.create(resource, params)
        }
      } catch (error) {
        console.log('seminar create error: ', error)
        return Promise.reject('error')
      }
    },
    update: <RecordType extends RaRecord = RaRecord>(
      resource: string,
      params: UpdateParams,
    ): Promise<UpdateResult<any>> => {
      try {
        let formData = new FormData()
        switch (resource) {
          case 'seminars':
            formData.append('id', params.id.toString())
            formData.append('name', params.data.name)
            formData.append('price', params.data.price.toString())
            formData.append('description', params.data.description)

            if (
              params.data.hasOwnProperty('filePath') &&
              params.data.filePath
            ) {
              if (params.data.filePath.hasOwnProperty('rawFile')) {
                const file = params.data.filePath.rawFile
                formData.append('filePath', file, file.name)
              } else {
                formData.append('filePath', params.data.filePath)
              }
            } else {
              formData.append('filePath', '')
            }

            return fetchJson(`${config.apiUrl}/${resource}/${params.id}`, {
              method: 'PUT',
              body: formData,
            }).then(({ json }) => ({
              data: { ...params.data, id: json.id },
            }))
          default:
            return dataProvider.update(resource, params)
        }
      } catch (error) {
        console.log('update seminar error: ', error)
        return Promise.reject(error)
      }
    },
  }
}

export default myDataProvider
