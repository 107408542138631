import {
  DateField,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin'
import { VideoPlayer } from '../../common/videoPlayer/VideoPlayer'

export const ShowPage = () => (
  <Show title="Просмотр семинара">
    <SimpleShowLayout>
      <div style={{ width: 350 }}>
        <VideoPlayer source="filePath" label="Видео" />
      </div>
      <TextField source="name" label="Название" />
      <TextField source="description" label="Описание" />
      <NumberField source="price" label="Цена" />
      <DateField source="timestamp" label="Дата создания" showTime={true} />
    </SimpleShowLayout>
  </Show>
)
