import React from 'react'
import { Admin, Resource } from 'react-admin'
import myDataProvider from './dataProvider/myDataProvider'
import polyglotI18nProvider from 'ra-i18n-polyglot'
// @ts-ignore
import russianMessages from 'ra-language-russian'
import { CustomLayout } from './layout/CustomLayout'
import authProvider from './Auth/authProvider'
import adminUsers from './containers/adminUsers'
import users from './containers/users'
import seminars from './containers/seminars'
import sales from './containers/sales'

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')

const App = () => {
  const dataProvider = myDataProvider()

  return (
    <Admin
      title="Панель управления"
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      layout={CustomLayout}
      disableTelemetry
    >
      <Resource
        name="admin-users"
        list={adminUsers.list}
        create={adminUsers.create}
        edit={adminUsers.edit}
      />
      <Resource
        name="users"
        list={users.list}
        create={users.create}
        edit={users.edit}
      />
      <Resource
        name="seminars"
        list={seminars.list}
        create={seminars.create}
        edit={seminars.edit}
        show={seminars.show}
      />
      <Resource
        name="sales"
        list={sales.list}
        create={sales.create}
        edit={sales.edit}
      />
    </Admin>
  )
}

export default App
