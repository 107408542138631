import {
  Create,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  FormDataConsumer,
  NumberInput,
  BooleanInput,
  AutocompleteInput,
} from 'react-admin'
import chooseProductIdResource from './functions/chooseProductIdResource'

export const CreatePage = () => {
  return (
    <Create title="Новая продажа" redirect="list">
      <SimpleForm>
        <ReferenceInput source="userId" required={true} reference="users">
          <AutocompleteInput label="Пользователь" optionText="fio" />
        </ReferenceInput>
        <SelectInput
          required={true}
          source="productType"
          label="Тип продукта"
          choices={[{ id: 0, name: 'семинары' }]}
        />
        <FormDataConsumer>
          {(formData: any, ...rest) =>
            typeof formData.formData.productType === 'number' && (
              <ReferenceInput
                source="productId"
                required={true}
                reference={chooseProductIdResource(
                  formData.formData.productType,
                )}
              >
                <AutocompleteInput label="Продукт" optionText="name" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <NumberInput
          source="expiration"
          label="Кол-во дней, доступных для просмотра"
          required={true}
        />
        <BooleanInput source="paid" label="Оплачен" defaultValue={true} />
        <NumberInput source="price" label="Цена" required={true} />
        <NumberInput
          source="discount"
          label="Скидка"
          required={true}
          defaultValue={0}
        />
      </SimpleForm>
    </Create>
  )
}
