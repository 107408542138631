import { CustomPagination } from '../../common/customPagination/CustomPagination'
import {
  Datagrid,
  DateField,
  EditButton,
  List,
  NumberField,
  SelectField,
  ReferenceField,
  BooleanField,
  TextField,
} from 'react-admin'
import { ProductField } from './components/productField/ProductField'

export const ListPage = () => {
  return (
    <List title="Списки продаж" empty={false} pagination={<CustomPagination />}>
      <Datagrid>
        <ReferenceField source="userId" label="Пользователь" reference="users">
          <TextField source="fio" />
        </ReferenceField>
        <SelectField
          source="productType"
          label="Тип продукта"
          choices={[{ id: 0, name: 'семинары' }]}
        />
        <ProductField label="Продукт" />
        <NumberField
          source="expiration"
          label="Кол-во дней, доступных для просмотра"
        />
        <BooleanField source="paid" label="Оплачен" />
        <NumberField source="price" label="Цена" />
        <NumberField source="discount" label="Скидка" />
        <DateField
          source="dateOfPurchase"
          label="Дата продажи"
          showTime={true}
        />
        <EditButton />
      </Datagrid>
    </List>
  )
}
