import { useRecordContext } from 'react-admin'
import { useEffect, useRef } from 'react'
import Hls from 'hls.js'
import config from '../../constants/config'
import inMemoryJWT from '../../Auth/inMemoryJWT'

export const VideoPlayer = (props: any) => {
  const record = useRecordContext(props)

  const fileName = typeof record === 'string' ? record : record.filePath

  const hlsUrl = `${config.apiUrl}/seminars/${fileName}/playlist.m3u8`
  const videoRef = useRef<HTMLVideoElement | null>(null)

  useEffect(() => {
    if (Hls.isSupported() && videoRef.current) {
      const hlsConfig = {
        xhrSetup: (xhr: XMLHttpRequest) => {
          xhr.setRequestHeader(
            'Authorization',
            `Bearer ${inMemoryJWT.getToken()}`,
          )
        },
      }

      const hls = new Hls(hlsConfig)
      hls.loadSource(hlsUrl)
      hls.attachMedia(videoRef.current)
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (videoRef.current) {
          videoRef.current.play()
        }
      })
    }
  }, [hlsUrl])

  return (
    <div>
      <video
        ref={videoRef}
        autoPlay={false}
        controls
        width="100%"
        height="auto"
      />
    </div>
  )
}
