import { ListPage } from './ListPage'
import { CreatePage } from './CreatePage'
import { EditPage } from './EditPage'
import { ShowPage } from './ShowPage'

const pages = {
  list: ListPage,
  create: CreatePage,
  edit: EditPage,
  show: ShowPage,
}

export default pages
