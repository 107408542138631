import { ProductTypes } from '../interfaces'

const chooseProductIdResource = (productType: ProductTypes): string => {
  switch (productType) {
    case ProductTypes.SEMINARS:
      return 'seminars'
    default:
      return 'seminars'
  }
}

export default chooseProductIdResource
