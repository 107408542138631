import { Menu } from 'react-admin'
import GroupIcon from '@mui/icons-material/Group'
import PersonIcon from '@mui/icons-material/Person'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'

export const CustomMenu = () => (
  <Menu>
    <Menu.Item
      to="/admin-users"
      primaryText="Администраторы"
      leftIcon={<GroupIcon />}
    />
    <Menu.Item
      to="/users"
      primaryText="Пользователи"
      leftIcon={<PersonIcon />}
    />
    <Menu.Item
      to="/seminars"
      primaryText="Семинары"
      leftIcon={<OndemandVideoIcon />}
    />
    <Menu.Item
      to="/sales"
      primaryText="Продажи"
      leftIcon={<PointOfSaleIcon />}
    />
  </Menu>
)
