import { ReferenceField, TextField, useRecordContext } from 'react-admin'
import chooseProductIdResource from '../../functions/chooseProductIdResource'

export const ProductField = ({ source }: any) => {
  const record = useRecordContext()
  if (!record) return null

  const { productType } = record
  if (typeof productType !== 'number') return null

  return (
    <ReferenceField
      source="productId"
      reference={chooseProductIdResource(productType)}
    >
      <TextField source="name" />
    </ReferenceField>
  )
}
