import { Create, SimpleForm, TextInput } from 'react-admin'

export const CreatePage = () => {
  return (
    <Create title="Создать администратора" redirect="list">
      <SimpleForm>
        <TextInput
          source="username"
          label="Логин"
          required={true}
          parse={(value: string) => (value ? value.trim() : '')}
        />
        <TextInput
          source="email"
          type="email"
          label="Email"
          required={true}
          parse={(value: string) => (value ? value.trim() : '')}
        />
        <TextInput
          source="password"
          type="password"
          label="Пароль"
          required={true}
          parse={(value: string) => (value ? value.trim() : '')}
        />
      </SimpleForm>
    </Create>
  )
}
