import {
  Datagrid,
  List,
  TextField,
  EditButton,
  NumberField,
  DateField,
  ShowButton,
} from 'react-admin'
import { CustomPagination } from '../../common/customPagination/CustomPagination'

export const ListPage = () => {
  return (
    <List
      title="Список семинаров"
      empty={false}
      pagination={<CustomPagination />}
    >
      <Datagrid>
        <TextField source="name" label="Название" />
        <TextField source="description" label="Описание" />
        <NumberField source="price" label="Цена" />
        <DateField source="timestamp" label="Дата создания" showTime={true} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  )
}
